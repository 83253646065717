import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import NavigationMenu from './components/NavigationMenu';

function App() {
  return (
    <Router>
      <div className="app-wrapper">
        <NavigationMenu />
        <Routes>
          <Route exact path = "/" element = {<Home />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
